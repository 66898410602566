// Bootstrap
@import 'bootstrap/functions';
@import 'bootstrap-variables';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';
@import 'bootstrap/root';
@import 'bootstrap/reboot';
@import 'bootstrap/grid';
@import 'bootstrap/type';
@import 'bootstrap/tables';
@import 'bootstrap/dropdown';
@import 'bootstrap/utilities';

// Added by Mihai
@import 'components/variables';
@import 'components/fonts';
@import 'components/firebaseui';
@import 'components/products';
@import 'components/snipcart';
@import 'components/modal';

// Libraries
@import 'libraries/hamburgers/hamburgers';

// Mixins
@import "mixins/animations";

// Components
// TODO: Determine what is redundant in this block in future PR
@import 'components/page';
@import 'components/header';
@import 'components/footer';
@import 'components/logo';
@import 'components/main-menu';
@import 'components/main-menu-mobile';
@import 'components/social';
@import 'components/hamburger';
@import 'components/buttons-and-forms';
@import 'components/content';
@import 'components/intro';
@import 'components/strip';
@import 'components/whitebox';
@import 'components/feature';
@import 'components/page-svg-background';
@import 'components/dashboard';
@import 'components/firebase-ui';

// Pages
@import 'pages/home';
@import 'pages/team/team-summary';
@import 'pages/team/page-team-list';
@import 'pages/testimonials/testimonials';
@import 'pages/services/page-services-list';
@import 'pages/services/service-single';
@import 'pages/services/service-summary';

.button {
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  padding: .75rem 1.75rem;
  background-color: darken($color-secondary, 4);
  border-radius: 3px;
  font-size: .9rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border-width: 0 !important;

  &.color-free  { background-color: darken($color-gray, 10); }
  &.color-lite  { background-color: lighten($color-lite, 20); }
  &.color-basic { background-color: lighten($color-basic, 10); }
  &.color-pro   { background-color: lighten($color-pro, 10); }

  &:hover {
    color: #fff;
    border-radius: 3px;
    background: lighten($color-secondary, 2%) !important;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    text-decoration: none;
  }

  &.btn-sm {
    font-size: 0.8rem;
    padding:0.5rem .9rem;
    border-radius: 4px;

  }
  &.btn-md {
    font-size: 1.0rem;
    padding: 0.8rem 1.2rem;
    border-radius: 6px;
  }
  &.btn-lg {
    font-size: 1.2rem;
    padding: 1.2rem 1.5rem;
    border-radius: 12px;
  }
  &.btn-xl {
    font-size: 1.3rem;
    padding: 1.5rem 1.85rem;
    background: linear-gradient(
      217deg, rgba(231, 84, 173, 1), rgba(231, 84, 173, 0.4) 80%),
      linear-gradient(127deg, rgba(123, 222, 226, 0.8), rgba(123, 222, 226, 1) 80%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, .7) 80%);
  }
}

button #client-portal {
  height: inherit;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  padding: .5rem 1rem !important;
  background: $color-secondary;

  &:hover {
    color: #fff;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }
}

input, textarea {
  transition: all 0.4s ease;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid $color-primary;
  padding: .5rem;
  margin-bottom: 1.25rem;
}

.toggle-content {
	display: none;
	height: 0;
  opacity: 0;
	overflow: hidden;
	transition: height 350ms ease-in-out, opacity 750ms ease-in-out;
}

.toggle-content.is-visible {
	display: block;
	height: auto;
  opacity: 1;
}

h2.success,
h3.success,
h4.success,
h5.success {
  color: $green;
}

h2.error,
h3.error,
h4.error,
h5.error {
  color: $red;
}




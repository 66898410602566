.feature {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 2rem 2.5rem 2.5rem;
  margin: 0 1rem;
  background: $rgba-primary-2;

  box-shadow: 0.05rem 0.05rem 0.15rem rgba(0,0,0,0.45);
  border-radius: 10px;
  flex-direction: column;
  text-align: center;

  .feature-title {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .feature-image {
    flex: 0 0 auto;
    width: 180px;
    height: auto;
    display: flex;
    margin-bottom: 20px;
    text-align: center;
  }

  img {
    width: 100%;
    height: auto;
  }

  .feature-content {
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;
    font-size: .9rem;

    p {
      margin: 0;
      strong {
        text-transform: uppercase;

      }
      em {
        font-style: normal;
      }
    }
  }
}

//
// Animation-related CSS mixins
//

// Stagger main menu text fly-in
@mixin anim-delay($base, $multiplier, $count) {
  animation-delay: unquote($base * ($multiplier * $count) + "s");
}

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// Rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// Translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}
// Skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform origin
@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

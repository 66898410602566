//
// Fonts and related mixins
//

// $body-font-family: $header-font-family;
$text-color: $color-gray-dark;

// Preset heading sizes
$h1FontSize: 2.4rem;
$h2FontSize: 2.0rem;
$h3FontSize: 1.6rem;
$h4FontSize: 1.4rem;
$h5FontSize: 1.0rem;
$h6FontSize: 0.8rem;
$font-size-base: 0.8rem;

// Base typography settings
$base-font-weight: 400 !default;
$small-font-size:  $font-size-base * 0.875 !default;
$base-line-height: 1.5 !default;

body {
  font-family: $body-font-family !important;
  color: $text-color;
  font-weight: 300;

  @include media-breakpoint-down(sm) { font-size: .8rem !important; }
  @include media-breakpoint-between(sm, md) { font-size: 0.9rem !important; }
  @include media-breakpoint-between(md, lg) { font-size: 0.95rem !important; }
  @include media-breakpoint-up(lg) { font-size: 1.0rem !important; }

  p {
    font-weight: 200;
    padding: 1rem 0 0.5rem;
    color: rgb(30, 30, 30);
    font-family: $body-font-family;
  }

  a {
    text-decoration: underline;
  }
}

// THEME DEFAULTS BELOW

ul,
ol {
  color: rgb(78, 78, 78);
  margin: 0;
  padding: 0;
  li {
    padding: 0;
    margin: 0;
  }
}

ul.no-bullet {
  list-style-type: none;
}


h1, h2, h3, h4, h5 {
  font-family: $header-font-family !important;
}
h4, h5, h6 {
  margin-bottom: 0;
}
h6 {
  font-family: $body-font-family;
}

h1 {
  font-size: $h1FontSize !important;
  line-height: 1.2;
  font-weight: $font-weight-light;
  color: $color-gray-dark;

  @include media-breakpoint-down(sm) {
    font-size: 0.8 * $h1FontSize;
    width: 100%;
  }
  @include media-breakpoint-between(sm, md) {
    font-size: 0.9 * $h1FontSize;
    line-height: 1.2;
    width: 60%;
  }
  @include media-breakpoint-between(md, lg) {
    font-size: $h1FontSize;
    width: 70%;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.1 * $h1FontSize;
    width: 50%;
  }
}

h2 {
  font-weight: $font-weight-light;
  line-height: 1.4;

  @include media-breakpoint-down(sm) {
    font-size: 0.8 * $h2FontSize;
  }
  @include media-breakpoint-between(sm, md) {
    font-size: 0.9 * $h2FontSize;
  }
  @include media-breakpoint-between(md, lg) {
    font-size: $h2FontSize;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.2 * $h2FontSize;
  }
}

h3 {
  color: $color-primary;
  font-size: $h3FontSize;
  font-weight: $font-weight-thin;
  line-height: 1.4;
  letter-spacing: 1px;
}

h2, h3, h4, h5 {
  font-weight: thin !important;
}

h5 {
  font-size: $h5FontSize;
}

h6 {
  font-size: $h6FontSize;
  font-weight: bold;
}

// Body copy only
article {
  ul,
  ol {
    color: $color-primary;
    font-weight: $font-weight-light;
    letter-spacing: 0.79px;
  }
}

.font-sm {
  $font-size-base: 0.8rem;
}

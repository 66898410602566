#firebaseui-container {
  .firebaseui-card-header {
    border-bottom: none;
  }
  .mdl-card {
    background: lighten($color-secondary, 35);
    border: 2px solid $color-secondary;
  }
  .mdl-textfield__input {
    font-family: inherit !important;
    background: #fff;
    padding: 0.75rem;
  }
}

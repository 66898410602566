//
// Navigation bar
//
.fixed {
  position: fixed;
  top: 0px;
  border-bottom: 10px solid $color-primary !important;
}
.hidden {
  opacity: 0.4 !important;
  -webkit-transition: opacity .8s;
	transition: opacity .8s ease-in-out;
}

.main-menu {
  height: inherit;
  display: none;
  z-index: 999;

  @include media-breakpoint-up(md) {
    display: block;
  }
  > ul {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      font-family: $body-font-family !important;
      color: #fff;
      height: inherit;
      margin: 0 !important;
      padding: 0 30px 0 0;
      list-style: none;

      @include media-breakpoint-between(md, lg) {
        padding: 0 8px 0 0;
      }

      &:last-of-type {
        margin: 0;
      }

      > a {
        height: inherit;
        display: inline-block;
        font-size: 0.9rem;
        font-weight: 300;
        text-transform: uppercase;
        color: inherit;
        border: 1px solid transparent;
        padding: 10px;
        text-decoration: none;
        margin: 0;

        &.current {
          opacity: 0.4;
        }

        &:hover {
          background: transparent;
          opacity: 0.8;
          border-radius: 8px;
          border: 1px solid #fff;
          color: #fff;
          text-decoration: none;
          transition: all 285ms ease-in 0s;
        }
      }
    }
    > li.active {
      > a {
        background: $color-primary;
        text-decoration: none;
        color: #fff;
        &:hover {
          text-decoration: none;
          background: $color-primary;
          transition: all 225ms ease-in 0s;
          color: #fff;
        }
        span {
          display: block;
        }
      }
      ul.sub-menu.hidden {
        display: flex;
        opacity: 0;
        transition: all 225ms ease-in 0s;
        z-index: 1;
      }
      ul.sub-menu {
        display: flex;
        opacity: 0.8;
        transition: all 225ms ease-in 0s;
      }
    }
  }
}

// Override when on main landing page
.header-main .main-menu {
  > ul {
    > li {
      > a {
        &:hover {
        }
      }
    }
    > li.active {
      > a {
        &:hover {
        }
      }
    }
  }
}

// Pure CSS dropdown button
.dropdown > a:before, .dropdown > button:before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: ' ';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #fff;
}

.dropdown {
  position: relative;
  display: inline-block;

  button {
    display: inline-block;
    width: 150px;
    padding-left: 10%;
    padding-right: 20%;
  }

  ul {
    position: absolute;
    top: 34px;
    border-radius: 3px;
    left: 0px;
    list-style: none;
    padding: 4px 0px;
    display: none;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,.175);

    li {
      display: block;
      padding: 12px 16px;
      white-space: nowrap;
      min-width: 160px;

      a {
        text-decoration: none;
        display: block;
        color: black
      }

      &:hover {
        background-color: lighten($color-tertiary, 30);
        cursor: pointer;
      }
    }
  }

  input[type=checkbox] {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin: 0px;
    opacity: 0;

    &:checked {
      position: fixed;
      z-index:+0;
      top: 0px; left: 0px;
      right: 0px; bottom: 0px;
    }
    &:checked + ul {
      display: block;
    }
  }

  .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
    font-size: 1px;
    padding: 0;
  }
}

.header {
  min-height: 80px;
  z-index: 1;
  padding: 1rem 0;
  background: rgba(13, 53, 153, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(50, 50, 93, 0.2);

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-social {
    height: inherit;
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &.header-main {
    background: rgba(13, 53, 153, 0.3) !important;
  }
}

#shopping-cart {
  position: absolute;
  left: 4%;
  top: 69px;
  z-index: 0;

  button {
    font-size: 0.7rem;
    background-color: $color-tertiary;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    border: none;

    &:hover {
      background: lighten($color-tertiary, 5%) !important;
      transform: translateY(-.25px);
    }
  }
}



.strip {
  margin-top: 200px;
  background: rgba(255,255,255, 0.9);
}
.strip-white {
  background: rgba(255,255,255, 0.88);
}

.strip-diagonal {
  transform: skewY(5deg);
  opacity: 0.92;
  padding: 6rem 0 8rem 0;
  margin: 8rem 0 5rem 0;

  > div {
    transform: skewY(-5deg);
  }
}

.strip-diagonal-2 {
  transform: skewY(-5deg);
  padding: 5rem 0;
  margin: 5rem 0;

  > div {
    transform: skewY(5deg);
  }
}

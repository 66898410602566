// Body content (everything BUT fonts)

hr.spacer {
  padding: .5vh 0;
  border-top: 0;

  @include media-breakpoint-down(sm) {
    padding: .25vh 0;
  }
  @include media-breakpoint-between(sm, md) {
    padding: .5vh 0;
  }
}

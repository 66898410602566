.intro {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3.5rem 0 4rem 0;

  @include media-breakpoint-down(sm)        { padding: 2rem 0; }
  @include media-breakpoint-between(sm, md) { padding: 2.5rem 0; }
  @include media-breakpoint-between(md, lg) { padding: 3rem 0; }
  @include media-breakpoint-up(lg)          { padding: 3.25rem 0; }

  h1 {
    color: $color-secondary-2-3;
    padding-left: .75rem;
    font-weight: 300;
    letter-spacing: 0.75px;
    line-height: 1.5;
    font-family: $font-family-heading;

    @include media-breakpoint-down(sm) {
      font-size: 1.8rem !important;
      width: 95%;
    }
    @include media-breakpoint-between(sm, md) {
      font-size: 2.2rem !important;
      width: 80%;
    }
    @include media-breakpoint-between(md, lg) {
      font-size: 2.4rem !important;
      width: 85%;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.6rem !important;
      width: 90%;
    }
  }

  p {
    color: $color-tertiary;
    padding: 2rem 0 0 .25rem;
    width: 80%;
    font-weight: 200;

    @include media-breakpoint-down(sm) {
      font-size: 1.0rem;
      line-height: 1.8;
      width: 90%;
    }
    @include media-breakpoint-between(sm, md) {
      font-size: 1.1rem;
      line-height: 2;
      width: 60%;
    }
    @include media-breakpoint-between(md, lg) {
      font-size: 1.2rem;
      line-height: 2.2;
      width: 72%;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
      line-height: 2.4;
      width: 68%;
    }
  }
}


.page-team-list {
  background-image: url('../images/team.svg');
  background-repeat: no-repeat;
  background-size: 115%;
  background-position: 40px 49px;
  @include media-breakpoint-up(sm) {
    background-size: 90%;
    background-position: 159px 40px;
  }
  @include media-breakpoint-up(md) {
    background-size: 85%;
    background-position: 199px 50px;
  }
  @include media-breakpoint-up(lg) {
    background-size: 60%;
    background-position: 100% 60px;
  }
}

$color-main: $color-primary;
$color-active: #fff;
$color-link: #fff;

$button-height: 27px;
$button-width: 35px;

.main-menu-mobile {
  position: fixed;
  background: $color-main;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  // Same as `transform: translateY(-100%)`
  @include translate(0, -50%);
  opacity: .5;
  transition: opacity 0.3s ease-in, transform 0.2s ease-in;

  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  // Presets that affect ul > li animations
  $base-delay: 0.1;
  $delay-multiplier: 1.1;

  &.open {
    visibility: visible;
    z-index: 10;

    // Same as `transform: translateY(0%)`
    @include translate(0%, 0);
    opacity: 1;

    ul {
      li {
        animation: fadeInRight 0.5s ease forwards;
        animation-delay: 0.35s;
        color: #fff;

        // Each pseudo-selector should match up with one of our menu options
        &:nth-of-type(1) {
          @include anim-delay($base-delay, $delay-multiplier, 2);
        }
        &:nth-of-type(2) {
          @include anim-delay($base-delay, $delay-multiplier, 3);
        }
        &:nth-of-type(3) {
          @include anim-delay($base-delay, $delay-multiplier, 4);
        }
        &:nth-of-type(4) {
          @include anim-delay($base-delay, $delay-multiplier, 5);
        }
        &:nth-of-type(5) {
          @include anim-delay($base-delay, $delay-multiplier, 6);
        }
        &:nth-of-type(6) {
          @include anim-delay($base-delay, $delay-multiplier, 7);
        }
      }
    }
  }


  ul {
    color: #fff;
    font-size: 2rem;
    font-family: $font-family-heading;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 0;

    li {
      display: block;
      position: relative;
      opacity: 0;
      padding: 0 .25rem;

      a {
        display: block;
        position: relative;
        color: $color-link;
        text-decoration: none;
        overflow: hidden;
        &:hover:after,
        &:focus:after,
        &:active:after {
          width: 100%;
        }

        &.current {
          opacity: 0.6;
          text-decoration: underline;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          transform: translateX(-50%);
          height: 3px;
          background: $color-link;
          transition: 0.35s;
        }
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 30%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.lock-scroll {
  overflow: hidden;
}

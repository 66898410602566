.page-home {
  background-image: url('../images/landing-page.svg');
  background-repeat: no-repeat;
  background-size: 115%;
  background-position: 49px -10px;
  @include media-breakpoint-up(sm) {
    background-size: 90%;
    background-position: 159px 0px;
  }
  @include media-breakpoint-up(md) {
    background-size: 70%;
    background-position: 100% 10px;
  }
  @include media-breakpoint-up(lg) {
    background-size: 60%;
    background-position: 100% 20px;
  }
}

p.card {
  background: #fefefe;
  padding: 2rem;
  border-radius: 5px;
}

.call {
  display: flex;
  position: relative;
  flex: 1 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);

  background-image: linear-gradient(217deg, rgba(231, 84,173,1), rgba(231, 84,173,.5) 72%),
          linear-gradient(127deg, rgba(123,222,226,1), rgba(123,222,226,.5) 72%),
          linear-gradient(336deg, rgba(0,0,255,1), rgba(0,0,255,.5) 72%);

  color: $secondary;

  flex-direction: column;
  @include media-breakpoint-down(sm) {
    // flex-direction: row;
    // width: 80%;
    &.call-wide {
      // width: 100%;
    }
  }
  @include media-breakpoint-up(md) {
    // width: 75%;
    &.call-wide {
      // width: 100%;
    }
  }
  @include media-breakpoint-up(lg) {
    // width: 50%;
    &.call-wide {
      // width: 100%;
    }
  }

  .call-box-top,
  .call-box-bottom {
    padding: 24px;
    @include media-breakpoint-down(sm) {
      padding: 10px;
    }
  }
  .call-box-top {
    flex: 1 0 auto;
    // font-size: 1.1rem;
    line-height: 1.75;
    font-weight: 400;
    color: #eee;
  }
  .call-box-bottom {
    flex: 0 0 auto;
  }
  .call-name {
    font-size: 1.1rem;
    font-weight: bold;
  }
  svg {
    fill: lighten($secondary, 40%);
    position: absolute;
    bottom: -9px;
    right: 0;
    width: 100px;
    height: 100px;
    @include media-breakpoint-up(sm) {
      width: 120px;
      height: 120px;
    }
  }
}
.certified {
  background-image: url('/images/undraw_experts3_3njd.svg');
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: -100px 0;
  overflow: visible;
  position: relative;
  display: inherit;
  padding-top: 220px;
  @include media-breakpoint-up(sm) {
    background-size: 90%;
    padding-top: 250px;
    background-position: -110px 30px;
  }
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-up(lg) {
    padding-top: 280px;
    background-size: 85%;
    background-position: -170px 0px;
  }
  h1 {
    text-align: right;
    float: right;
    color: $color-primary;
    margin-top: -30%;
    color: $color-primary;
    font-size: 2.4rem;
    line-height: 1.2;
    font-family: $font-family-heading;
    margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
      width: 70%;
    }
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
      margin-top: -20%;
      width: 70%;
    }
  }
}

//
// Variables
// Color Scheme, Typography and the like.
//

// Heading and document fonts

@import url('https://fonts.googleapis.com/css?family=Chivo:300,400,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,700&display=swap');

$header-font-family: 'Chivo', 'Helvetica Neue', Helvetica, sans-serif !default;
$body-font-family: 'Poppins', Arial, sans-serif !default;

// Color scheme
// http://paletton.com/#uid=53M0u0kpeIieGRXknMOuPE5ASsz

$background-color: #fdfdfd !default;

// As hex codes
// http://paletton.com/#uid=53M0u0kpeIieGRXknMOuPE5ASsz
$color-primary-0: #3D66CE;	// Main Primary color */
$color-primary-1: #89A4E7;
$color-primary-2: #5E81DA;
$color-primary-3: #1B4BC3;
$color-primary-4: #0D3599;

$color-secondary-1-0: #633FD0;	// Main Secondary color (1) */
$color-secondary-1-1: #A28AE8;
$color-secondary-1-2: #7F60DC;
$color-secondary-1-3: #471DC6;
$color-secondary-1-4: #320E9C;

$color-secondary-2-0: #2BBDC4;	// Main Secondary color (2) */
$color-secondary-2-1: #7BDEE2;
$color-secondary-2-2: #4DCCD2;
$color-secondary-2-3: #08AFB7;
$color-secondary-2-4: #01838A;



// As RGBa codes */
$rgba-primary-0: rgba( 61,102,206,1);	// Main Primary color */
$rgba-primary-1: rgba(137,164,231,1);
$rgba-primary-2: rgba( 94,129,218,1);
$rgba-primary-3: rgba( 27, 75,195,1);
$rgba-primary-4: rgba( 13, 53,153,1);

$rgba-secondary-1-0: rgba( 99, 63,208,1);	// Main Secondary color (1) */
$rgba-secondary-1-1: rgba(162,138,232,1);
$rgba-secondary-1-2: rgba(127, 96,220,1);
$rgba-secondary-1-3: rgba( 71, 29,198,1);
$rgba-secondary-1-4: rgba( 50, 14,156,1);

$rgba-secondary-2-0: rgba( 43,189,196,1);	// Main Secondary color (2) */
$rgba-secondary-2-1: rgba(123,222,226,1);
$rgba-secondary-2-2: rgba( 77,204,210,1);
$rgba-secondary-2-3: rgba(  8,175,183,1);
$rgba-secondary-2-4: rgba(  1,131,138,1);


$rgba-secondary-1-0: rgba(223, 47,154,1);	// Main Secondary color (1) */
$rgba-secondary-1-1: rgba(240,130,196,1);
$rgba-secondary-1-2: rgba(231, 84,173,1);
$rgba-secondary-1-3: rgba(216,  8,134,1);
$rgba-secondary-1-4: rgba(179,  0,109,1);


// SET COLOR VARIABLES
$color-primary: $color-primary-0;
$color-primary-dark: $color-primary-4;
$color-secondary: $color-secondary-1-2;
$color-secondary-dark: $color-secondary-1-3;

$color-tertiary: $color-secondary-2-2;
$color-accent: #7BDDE2;

$link-color: lighten($color-primary,10);
$link-color-hover: darken($color-primary,10);

$color-blue-light: lighten($color-primary, 15);
$color-blue-lighter: lighten($color-blue-light, 15);

$color-gray: #aaa !default;
$color-gray-light: lighten($color-gray, 10%);
$color-gray-lighter: lighten($color-gray, 20%);
$color-gray-dark: darken($color-gray, 15%);
$color-gray-darker: darken($color-gray, 55%);

// Cover SnipCart's preset colors
$grey-color-dark: $color-gray-light;
$grey-color-light: $color-gray-darker;

$color-lite: $color-secondary-1-0;
$color-basic: $color-primary-2;
$color-pro: $color-secondary-2-4;
.color-free  { background-color: darken($color-gray, 10); }
.color-lite  { background-color: lighten($color-lite, 20); }
.color-basic { background-color: darken($color-basic, 10); }
.color-pro   { background-color: lighten($color-pro, 10); }
